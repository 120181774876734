<template>
  <div class="login-wrapper">
    <div class="div-header">
      <div class="row row-header">
        <div class="col-6 logo">
          <img
            alt="Panasonic Logo"
            class="nav-logo"
            contain
            src="@/assets/logo-KTC.png"
          />
        </div>
      </div>
    </div>
    <div class="row row-title-page">
      <v-col cols="12">
        <div class="app-title">
          <div class="top"></div>
          <div class="middle">Smart Lighting Solution</div>
        </div>
      </v-col>
    </div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row align-items-center justify-content-center card-login">
          <div class="col-md-8 col-lg-6 col-xl-5 col-card-login">
            <div class="card card-login">
              <div class="card-body shadow p-4">
                <v-form
                  @submit.prevent="submit"
                  autocomplete="new-password"
                  v-model="valid"
                >
                  <v-row no-gutters>
                    <v-col cols="3" class="login-label">Username</v-col>
                    <v-col cols="9">
                      <v-text-field
                        autocomplete="new-password"
                        label=""
                        class="login-input"
                        v-model="userName"
                        required
                        outlined
                        filled
                        single-line
                        prepend-inner-icon="fas fa-user"
                        :rules="userValidation"
                      ></v-text-field>
                      <div
                        class="error-message error-message-pink"
                        v-if="this.errUserCount > 0"
                      >
                        {{ userValidationMsg }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="3" class="login-label">Email</v-col>
                    <v-col cols="9">
                      <v-text-field
                        autocomplete="new-password"
                        label=""
                        class="login-input"
                        v-model="email"
                        min="8"
                        required
                        outlined
                        filled
                        single-line
                        prepend-inner-icon="fas fa-envelope"
                        :rules="emailValidation"
                      ></v-text-field>
                      <div
                        class="error-message error-message-pink"
                        v-if="this.errEmailCount > 0"
                      >
                        {{ emailValidationMsg }}
                      </div>
                      <div
                        id="error-text"
                        class="error-message error-message-pink mb-20"
                        v-if="!success && msg !== ''"
                      >
                        {{ msg }}
                      </div>
                    </v-col>
                  </v-row>
                  
                  <v-row no-gutters>
                    <v-col cols="12" class="forgot-link text-right">
                      <router-link to="/"> Back to Login </router-link>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12 text-center">
                      <button
                        type="submit"
                        class="button-login"
                      >
                        Reset Password
                      </button>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-title">
      <span>{{ ktc_version }}</span>
    </div>
    <div class="footer-copyright">
      <h5 style="color: #b5b5b5">
        <span style="color: #ffffff"
          ><span style="color: #ffffff; font-weight: bold">Powered by </span
          >Panasonic</span
        >
      </h5>
    </div>
      <v-dialog
        v-model="messageDialog"
        max-width="600"
        persistent
        content-class="profile-confirm-dialog"
      >
        <v-card id="profile-confirm">
          <v-card-title>Reset Password</v-card-title>
          <v-card-text
            ><v-row no-gutters>
              <v-col cols="12" style="padding-top: 6px">
                <div class="text-center" v-html="message"></div>
              </v-col>
              <v-col cols="12 pt-4" class="text-center">
                <button class="ml-4 btn btn-primary btn-custom" :ripple="false" @click="closeMessage">OK</button>
              </v-col>
            </v-row></v-card-text
          >
        </v-card>
      </v-dialog>
  </div>
</template>
<style src="../assets/css/login.css"></style>

<script>
import { auth } from "../services";

export default {
  data() {
    return {
      ktc_version: "Smart Lighting Solution Ver.2.0.0 ©2022",
      userName: "",
      email: "",
      msg: "",
      errorText: "",
      userValidationMsg: "",
      emailValidationMsg: "",
      errUserCount: 0,
      errEmailCount: 0,
      valid: false,
      success: true,
      message: '',
      messageDialog: false
    };
  },
  async created() {
    this.ktc_version = await this.getVersion();
  },
  computed: {
    userValidation() {
      return [
        (v) => {
          if (this.errUserCount == 0) {
            this.errUserCount++;
            return false;
          }
          if (!v) {
            this.valid = false;
            this.userValidationMsg = "Username is required";
            return false;
          } else {
            this.userValidationMsg = "";
            return true;
          }
        },
      ];
    },
    emailValidation() {
      return [
        (v) => {
          if (this.errEmailCount == 0) {
            this.errEmailCount++;
            return false;
          }
          if (!v) {
            this.valid = false;
            this.emailValidationMsg = "Email is required";
            return false;
          } else if (
            !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            )
          ) {
            this.emailValidationMsg = "Email must be valid";
            this.valid = false;
            return false;
          } else {
            this.emailValidationMsg = "";
            return true;
          }
        },
      ];
    },
  },
  methods: {
    submit() {
      const data = {
        username: this.userName,
        email: this.email,
      };
      if (this.userName && this.email && this.userValidation && this.emailValidation && this.valid) {
        return auth
          .forgotPassword(data)
          .then((res) => {
            if (res.status == 200 && res.data.success) {
              this.message = "Reset password token generated successfully. Please check your email setup new password";
              if (res.data.message) {
                this.message = res.data.message;
              }
              this.messageDialog = true;
              this.success = true;
            } else {
              throw res;
            }
          })
          .catch((err) => {
            this.success = false;
            this.msg = "Please check if your username and email are correct";
              if (err?.response?.data?.message) {
                this.msg = err?.response?.data?.message;
              }
          });
      }
    },
    closeMessage() {
      this.messageDialog = false;
      this.message = "";
      this.$router.push('/login');
    },
  },
};
</script>
